<template>
  <div class="page-box box">
    <van-search
      v-model="schoolName"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
    >
      <template #action>
        <div
          class="hr-btn"
          @click="onSearch"
        >
          搜索
        </div>
      </template>
    </van-search>

    <div
      v-show="searchArr && searchArr.length > 0"
      class="history-box box"
    >
      <div class="title">
        历史搜索
      </div>
      <van-icon
        class="delete-btn"
        name="delete-o"
        @click="deletSearch"
      />
      <div class="box tag-box">
        <div
          v-for="(item, index) in searchArr"
          :key="index"
          class="tag"
          @click="tagBtn(item)"
        >
          {{ item.content }}
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <div
      class="box"
      style="padding: 0 15px;"
    >
      <template v-if="schoolList && schoolList.length > 0">
        <template v-for="(item,index) in schoolList">
          <div
            :key="index"
            class="box"
            @click="goSchoolDetail(item)"
          >
            <list-item
              :item="item"
            ></list-item>
          </div>
        </template>
      </template>
      <van-empty
        v-else
        description="暂无搜索结果"
      />
    </div>
  </div>
</template>

<script>
import ListItem from "@/components/list-item/school-item.vue"
export default {
  name: 'SearchIndex',
  components: {
    ListItem
  },
  data () {
    return {
      value: '',
      schoolName: '',
      schoolList: [],
      searchArr: []
    }
  },
  computed: {
  },
  created () {
    try {
      wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          window.localStorage.setItem('latitude', latitude)
          window.localStorage.setItem('longitude', longitude)
        },
        complete () {
        }
      });
    } catch (e) {
      console.log(e)
    }
    this.getHistory()
  },
  methods: {
    async getList () {
      if (this.schoolName) {
        let data = {
          schoolName: this.schoolName,
          pageNo: 1,
          pageSize: 100,
          lat: window.localStorage.getItem('latitude') || '',
          lng: window.localStorage.getItem('longitude') || ''
        }
        let res = await this.$http.homeApi.schoolList(data)
        console.log(res, 'list')
        if (res.code === 200) {
          this.schoolList = res.msg.data || []
          this.getHistory()
        }
      } else {
        this.$toast.fail('请输入搜索词')
      }
    },
    async getHistory () {
      let res = await this.$http.homeApi.searchHistory({})
      console.log(res, '搜索')
      if (res.code === 200) {
        this.searchArr = res.msg || []
      }
    },
    async deletSearch () {
      let res = await this.$http.homeApi.searchDelete({})
      if (res.code === 200) {
        this.$toast.success('删除成功')
        this.getHistory()
      }
    },
    goSchoolDetail (item) {
      this.$router.push(`/school/detail?id=${item.schoolNo || ''}`)
    },
    onSearch (e) {
      console.log(e)
      this.getList()
    },
    tagBtn (item) {
      this.schoolName = item.content || ''
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  .van-search__content {
    border-radius: 16px;
  }
  .history-box {
    padding: 12px;
    .title {
      height: 32px;
      line-height:32px;
      font-size:16px;
      font-weight: bold;
    }
    .delete-btn {
      position: absolute;
      right: 12px;
      top: 21px;
      color: #50657D;
    }
    .tag-box {
      margin-top: 8px;
      .tag {
        height: 28px;
        line-height:28px;
        padding: 0 16px;
        text-align: center;
        color: #343B4A;
        background: #F4F5F7;
        border-radius: 14px;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
